import React, { useState } from 'react';
import axios from 'axios';


const TextInputPage = () => {
  // State to hold the input text
  const [inputText, setInputText] = useState('');

  // Handle changes to the text input
  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const callApi = async (text) => {
    try {
      // Make the POST request to the API
      const response = await axios.post('http://www.animatedoctowaddle.com/api/generateAndDeploy', {
        promt: text// Sending the text in the request body
      });
  
      // Handle the response
      console.log('Response from API:', response.data);
      return response.data; // Optionally return the response data
    } catch (error) {
      // Handle any error that occurs during the API call
      console.error('Error making POST request:', error);
      throw error; // Rethrow the error if you want to handle it later
    }
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // You can process the input text here, like sending it to an API
    callApi(inputText)
    alert('Submitted text:\n' + inputText);
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Text Input Page</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="largeTextInput">
          Enter your text:
        </label>
        <br />
        <textarea
          id="largeTextInput"
          name="largeTextInput"
          rows="10"   // This sets the height of the textarea
          cols="50"   // This sets the width of the textarea
          value={inputText}
          onChange={handleInputChange}
          style={{ width: '100%', padding: '10px', marginTop: '10px' }}
        />
        <br />
        <button type="submit" style={{ marginTop: '10px', padding: '10px 20px' }}>
          Submit
        </button>
      </form>
    </div>
  );
};

export default TextInputPage;